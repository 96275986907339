// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,500;1,600;1,700;1,800&family=Rubik:wght@300;400;500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.MuiBox-root.css-19kzrtu {
  padding: 0;
  background-color: #fcfcfc;
}

.zoom {
  transition: transform 0.2s;
}
.zoom:hover { /* IE 9 */ /* Safari 3-8 */
  transform: scale(1.1);
}
.rdrDefinedRangesWrapper, .rdrDateDisplayWrapper, .rdrMonthAndYearWrapper {
  display:none;
}
.rdrMonthAndYearWrapper {
  display: none !important;
}
.MuiList-padding{
  padding: 0 !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #232674;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #232674;
}

.attention {
  color: #6a3fdc;
  font-weight: bold;
}

.calendarElement{
  position: absolute;
  left: 50%;
  transform: translateX(-100%);
  top:-50px;
  border:1px solid #ccc;
  z-index: 2000;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAIA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;AACA,cAC6B,SAAS,EACL,eAAe;EAC9C,qBAAqB;AACvB;AACA;EACE,YAAY;AACd;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,qBAAqB;AACvB;AACA,UAAU;AACV;EACE,WAAW;AACb;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;AACrB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,4BAA4B;EAC5B,SAAS;EACT,qBAAqB;EACrB,aAAa;AACf","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,500;1,600;1,700;1,800&family=Rubik:wght@300;400;500;600&display=swap\");\n\n@import url(\"https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap\");\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Rubik\", sans-serif;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n.MuiBox-root.css-19kzrtu {\n  padding: 0;\n  background-color: #fcfcfc;\n}\n\n.zoom {\n  transition: transform 0.2s;\n}\n.zoom:hover {\n  -ms-transform: scale(1.1); /* IE 9 */\n  -webkit-transform: scale(1.1); /* Safari 3-8 */\n  transform: scale(1.1);\n}\n.rdrDefinedRangesWrapper, .rdrDateDisplayWrapper, .rdrMonthAndYearWrapper {\n  display:none;\n}\n.rdrMonthAndYearWrapper {\n  display: none !important;\n}\n.MuiList-padding{\n  padding: 0 !important;\n}\n/* width */\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #232674;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #232674;\n}\n\n.attention {\n  color: #6a3fdc;\n  font-weight: bold;\n}\n\n.calendarElement{\n  position: absolute;\n  left: 50%;\n  transform: translateX(-100%);\n  top:-50px;\n  border:1px solid #ccc;\n  z-index: 2000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
