import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSearchDestination = createAsyncThunk(
  "search",
  async ({ url }) => {
    try {
      const response = await axios.get(url)
      // const response = await axios.get(url, {
      //   headers: {
      //     authorization: 'Token ' + "edea5e74cc7ffba1f71d885d26c4155603aef88e",
      //   },
      // });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

const searchSlice = createSlice({
  name: "seacrhDetails",
  initialState: {
    loading: false,
    searchDestination: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSearchDestination?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSearchDestination?.fulfilled, (state, action) => {
        state.loading = false;
        state.searchDestination = action.payload?.data;
      })
      .addCase(getSearchDestination?.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default searchSlice.reducer;
