import { createSlice } from '@reduxjs/toolkit';

const paymentSummarySlice = createSlice({
    name: 'paymentSummary',
    initialState: {
        data: {},
    },
    reducers: {
        setPaymentSummaryData: (state, action) => {
            state.data = action.payload;
        },
    },
});

// Export actions from the slice
export const { setPaymentSummaryData } = paymentSummarySlice.actions;

// Export the reducer to be combined in the store
export default paymentSummarySlice.reducer;