import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import userSlice from "../feature/userSlice";
import homeSlice from "../feature/homeSlice";
import productSlice from "../feature/productSlice";
import topDestinationSlice from "../feature/topDestinationSlice";
import browseByPropertySlice from "../feature/browseByPropertySlice";
import wishlistSlice from "../feature/wishlistSlice";
import bookingSlice from "../feature/bookingSlice";
import listPropertySlice from "../feature/listPropertySlice";
import currencySlice from "../feature/currencySlice";
import currencyConversionSlice from "../feature/currencyConversionSlice";
import searchSlice from "../feature/searchSlice";
import dashboardCurrencyConversionSlice from "../feature/dashboardCurrencyConversionSlice";
import geoLocationSlice from "../feature/geoLocationSlice";
import paymentSummarySlice from "../feature/paymentSummarySlice";

const rootReducer = combineReducers({
  isUser: userSlice,
  isHome: homeSlice,
  isProduct: productSlice,
  isTopDestination: topDestinationSlice,
  isBrowseByProperty: browseByPropertySlice,
  isWishlist: wishlistSlice,
  isBooking: bookingSlice,
  isListProperty: listPropertySlice,
  isCurrency: currencySlice,
  isCurrencyConversion: currencyConversionSlice,
  isSearch: searchSlice,
  isDashboardCurrencyConversion : dashboardCurrencyConversionSlice,
  isGeolocation: geoLocationSlice,
  isPaymentSummary: paymentSummarySlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["isProduct", "isBrowseByProperty", "isTopDestination"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["persist/PERSIST"],
      // Optionally ignore specific paths or keys
      ignoredPaths: ["register"],
    },
  }),
  // Enable Redux DevTools in development mode
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
