import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getProductDetails = createAsyncThunk(
  "getProductDetails",
  async (data) => {
    try {
      return data;
    } catch (error) {
      return null;
    }
  }
);

export const reserveDestination = createAsyncThunk(
  "reserveDestination",
  async ({ url ,data}) => {
    try {
      const response = await axios.post(url, data,{
        headers: {
          authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

const productSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    productDetails: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductDetails?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductDetails?.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetails = action.payload;
      })
      .addCase(getProductDetails?.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default productSlice.reducer;
