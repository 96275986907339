import React from "react";
import Footer from "../Footer";
import { Box } from "@mui/material";
import Navbar from "../Navbar";

const Layout = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          display: {
            position: "sticky",
            top: 0,
            zIndex: 10,
          },
        }}
      >
        <Navbar />
      </Box>

      <main style={{ minHeight: "100vh", backgroundColor:"#F6FAFE" }}>{children}</main>

      <Footer />
    </>
  );
};

export default Layout;
