import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBooking = createAsyncThunk(
  "getBooking",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: 'Token ' + localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    loading: false,
    allBooking: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBooking?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBooking?.fulfilled, (state, action) => {
        state.loading = false;
        state.allBooking = action.payload
      })
      .addCase(getBooking?.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default bookingSlice.reducer;
