import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


// Define an asynchronous thunk action to fetch country data from an API
// export const fetchCountryData = createAsyncThunk(
//   'currencyChange/fetchCountryData',
//   async () => {
//     const response = await fetch('http://ip-api.com/json/');
//     const data = await response.json();
//     // Return the country name from the API response
//     return data.country;
//   }
// );

  const currencySlice = createSlice({
    name: "currencyChange",
  initialState: {
    currentCurrency: "EUR",
    prevCurrency:""
  },
  reducers: {
    setCurrency: (state, action) => {
        
        state.currentCurrency = action.payload;
    },
    setPrevCurrency:(state, action) =>{
      state.prevCurrency = state.currentCurrency;
    },
  },
  
  // extraReducers: (builder) => {
  //   builder.addCase(fetchCountryData.fulfilled, (state, action) => {
  //     const countryName = action.payload; // The country name from the API response

  //     // Check the country name and set the current currency accordingly
  //     if (countryName.toLowerCase() === 'india') {
  //       state.currentCurrency = 'INR';
  //     } else {
  //       state.currentCurrency = 'EUR';
  //     }
  //   });
  // },
  })

  export const { setCurrency, setPrevCurrency } = currencySlice.actions;
export default currencySlice.reducer;