import { Button,
useMediaQuery,
} from "@mui/material";

import React from 'react'

const Buttons = ({ onClick, startIcon, bgColor, children}) => {

    const xs = useMediaQuery("(max-Width: 600px)");
    const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");

  return (
    <Button
    onClick={onClick}
    size={xs ? "small" : sm ? "medium" : "medium"}
    startIcon={startIcon}
    sx={{
        borderRadius:"5px",
        height: "2.3rem",
        textTransform: "capitalize",
        color: "White",
        fontWeight: "400",
        backgroundColor:bgColor,
        minWidth:"unset",
        px:".8rem",
        ml: { xs: ".5rem", sm: "1rem" },
        '& .MuiButton-startIcon': { display: {xs:"none", sm:"block", md:"block"} },
        '&:hover':{
          backgroundColor:bgColor,
          color:"White"
        }
    }}
    >
        {children}
    </Button>
  )
}

export default Buttons