import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getDashboardCurrencyConversion = createAsyncThunk(
  "getDashboardCurrencyConversion",
  async ({ url, productId }) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return { data, productId }; // Include productId in the returned payload
    } catch (error) {
      return null;
    }
  }
);

const dashboardCurrencyConversionSlice = createSlice({
  name: "dashboardCurrencyConversion",
  initialState: {
    data: null,
  },
  reducers: {
    updateDashboardCurrencyData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCurrencyConversion.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardCurrencyConversion.fulfilled, (state, action) => {
        state.loading = false;
        // Store the conversion data along with the productId
        state.data = { ...state.data, [action.payload.productId]: action.payload.data };
        state.error = null;
      })
      .addCase(getDashboardCurrencyConversion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export const { updateDashboardCurrencyData } = dashboardCurrencyConversionSlice.actions;

export default dashboardCurrencyConversionSlice.reducer;
