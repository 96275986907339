import { useEffect, Suspense } from "react";
import "./App.css";
import Layout from "./Components/Layout";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import { useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import VoyagerTalesLogo from "./Assets/VoyagerTalesLogo.svg";
import { getWishlist } from "./Redux/feature/wishlistSlice";
import { useDispatch } from "react-redux";

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.history.replaceState(null, "");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (userId && token) {
      dispatch(
        getWishlist({
          url: `${process.env.REACT_APP_API}api/favorite-destination/`,
        })
      );
    }
  }, []);

  return (
    <>
      <Layout>
        <Toaster position="top-right" reverseOrder={false} />
        <Helmet>
          <title>Voyager Tales</title>
          <meta
            name="description"
            content="Vacation Rentals, Homes, Experiences & Places - Voyager Tales"
          />
          <link
            rel="icon"
            type="image/png"
            href={VoyagerTalesLogo}
            sizes="16x16"
          />
        </Helmet>
        <Suspense>
          <ProtectedRoutes />
        </Suspense>
      </Layout>
    </>
  );
}

export default App;
