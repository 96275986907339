import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createWishlist = createAsyncThunk(
  "createWishlist",
  async ({ url,data }) => {
    try {
      const response = await axios.post(url,data, {
        headers: {
          authorization: 'Token ' + localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

export const getWishlist = createAsyncThunk(
  "getWishlist",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: 'Token ' + localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

// export const deleteWishlist = createAsyncThunk(
//   "deleteWishlist",
//   async ({ url, data}) => {
//     try{
//       const response = await axios.delete(url,data, {
//         headers:{
//           authorization: 'Token ' + localStorage.getItem("token"),
//         }
//       })
//       return response ? response?.data : null;
//     } catch (error) {
//       return null
//     }
//   } 
// )

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    loading: false,
    wishlist: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWishlist?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWishlist?.fulfilled, (state, action) => {
        state.loading = false;
        state.wishlist = action.payload
      })
      .addCase(getWishlist?.rejected, (state) => {
        state.loading = false;
      })
      // .addCase(deleteWishlist?.pending, (state) =>{
      //   state.loading = true;
      // })
      // .addCase(deleteWishlist?.fulfilled, (state, action) => {
      //   state.loading = false
      //   state.wishlist = action.payload
      // })
      // .addCase(deleteWishlist?.rejected, (state) => {
      //   state.loading = false;
      // });
  },
});

export default wishlistSlice.reducer;
