import { Newspaper } from "@mui/icons-material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



export const getGeoLocation = createAsyncThunk(
  "getGeoLocation",
  async ({ url }) => {
    try {
      
      const response = await axios.get(url, {
        headers: {
        },
      });

      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

const geoLocationSlice = createSlice({
  name: "geoLocationDetails",
  initialState: {
    // loading: false,
    geoLocationData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGeoLocation?.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getGeoLocation?.fulfilled, (state, action) => {
        // state.loading = false;
        state.geoLocationData = action.payload;
      })
      .addCase(getGeoLocation?.rejected, (state) => {
        // state.loading = false;
      });
  },
});


export default geoLocationSlice.reducer;
