import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { lazy, Suspense } from "react";
import { NavLink, useNavigate } from "react-router-dom";
const MailIcon = lazy(() => import('@mui/icons-material/Mail'));
const FaInstagram  = lazy(() => import('react-icons/fa6').then(module => ({ default: module.FaInstagram })));
const FaLinkedin  = lazy(() => import('react-icons/fa6').then(module => ({ default: module.FaLinkedin  })));
const FaYoutube  = lazy(() => import('react-icons/fa6').then(module => ({ default: module.FaYoutube  })));
const FaXTwitter = lazy(() => import('react-icons/fa6').then(module => ({ default: module.FaXTwitter })));
const FaFacebookF = lazy(() => import('react-icons/fa6').then(module => ({ default: module.FaFacebookF  })));

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ backgroundColor: "#ebeff7", }}>
        <Container
          maxWidth="lg"
          disableGutters={true}
          sx={{
            padding: ".5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row", md: "row" },
              justifyContent: "space-between",
              mt: { xs: 3, sm: 7, md: 7 },
              mb: { xs: 5, sm: 9, md: 9 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: { xs: 1, sm: 0, md: 0 },
              }}
            >
              <Box
                onClick={() => navigate("/")}
                sx={{
                  height: { xs: "5rem", sm: "3.5rem" },
                  width: { xs: "5rem", sm: "3.5rem" },
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <img
                  src={require("../../Assets/Company/logo.png")}
                  alt="No Preview"
                  style={{ height: "100%", width: "100%", objectFit: "fill" }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../Assets/Images/no-preview.jpeg");
                  }}
                />
              </Box>
              <Typography
                fontWeight={700}
                sx={{ fontSize: { xs: "1.8rem", sm: "1.5rem", }, color:"#232674", fontFamily:"Quicksand" }}
              >
                Voyagers Tales
              </Typography>
              <Box
                sx={{
                  display:"flex",
                  flexDirection:"column"
                }}
              >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: { xs: "13rem", sm: "11rem" },
                  mt: 3,
                }}
              >
                <NavLink
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  to="https://m.facebook.com/voyagerstales"
                  aria-label="Voyager's Tales Facebook Page"
                >
                  <Box
                    sx={{
                      height: { xs: "3rem", sm: "2.5rem" },
                      width: { xs: "3rem", sm: "2.5rem" },
                      borderRadius: "50%",
                      boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white.main",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Suspense>
                      <FaFacebookF  />
                    </Suspense>
                  </Box>
                </NavLink>
                <NavLink
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  to="https://instagram.com/voyagers.tales"
                  aria-label="Voyager's Tales Instagram Page"
                >
                  <Box
                    sx={{
                      height: { xs: "3rem", sm: "2.5rem" },
                      width: { xs: "3rem", sm: "2.5rem" },
                      borderRadius: "50%",
                      boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white.main",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Suspense>
                      <FaInstagram />
                    </Suspense>
                  </Box>
                </NavLink>
                <NavLink
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  to="https://twitter.com/VoyagersTales"
                  aria-label="Voyager's Tales Twitter Page"
                >
                  <Box
                    sx={{
                      height: { xs: "3rem", sm: "2.5rem" },
                      width: { xs: "3rem", sm: "2.5rem" },
                      borderRadius: "50%",
                      boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white.main",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Suspense>
                      <FaXTwitter />
                    </Suspense>
                  </Box>
                </NavLink>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: { xs: "13rem", sm: "11rem" },
                  mt: 3,
                }}
              >
              <NavLink
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  to="https://www.linkedin.com/company/voyagerstales/"
                  aria-label="Voyager's Tales Linkedin Page"
                >
                  <Box
                    sx={{
                      height: { xs: "3rem", sm: "2.5rem" },
                      width: { xs: "3rem", sm: "2.5rem" },
                      borderRadius: "50%",
                      boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white.main",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Suspense>
                      <FaLinkedin  />
                    </Suspense>
                  </Box>
                </NavLink>
                <NavLink
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  to="https://www.youtube.com/@VoyagersTales"
                  aria-label="Voyager's Tales Youtube Page"
                >
                  <Box
                    sx={{
                      height: { xs: "3rem", sm: "2.5rem" },
                      width: { xs: "3rem", sm: "2.5rem" },
                      borderRadius: "50%",
                      boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white.main",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Suspense>
                      <FaYoutube />
                    </Suspense>
                  </Box>
                </NavLink>
              </Box>
              </Box>
            </Box>
            <Box
              sx={{
                mt: { xs: 3, sm: 0 },
                width: { xs: "100%", sm: "70%", md: "65%" },
                display: "flex",
                flexDirection: { xs: "row", sm: "row", md: "row" },
                justifyContent: "space-between",
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  width: { xs: "50%", sm: "auto" },
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  sx={{
                    fontSize: { xs: "1.5rem", sm: "1rem", md: "1.125rem" },
                    color: "primaryText.main",
                    mb: { xs: -0.5, sm: 1, md: 1 },
                  }}
                >
                  Company
                </Typography>
                <Typography
                  onClick={() => navigate("/aboutUs")}
                  fontWeight={400}
                  sx={{
                    fontSize: { sm: ".8rem", md: "1rem" },
                    color: "footerText.main",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  About us
                </Typography>

                <Typography
                  onClick={() => navigate("/howWeWork")}
                  fontWeight={400}
                  sx={{
                    fontSize: { sm: ".8rem", md: "1rem" },
                    color: "footerText.main",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  How we work
                </Typography>
                <Typography
                  onClick={() => navigate("/PrivacyPolicy")}
                  fontWeight={400}
                  sx={{
                    fontSize: { sm: ".8rem", md: "1rem" },
                    color: "footerText.main",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Privacy Policy
                </Typography>
                <Typography
                  onClick={() => navigate("/TermsAndConditions")}
                  fontWeight={400}
                  sx={{
                    fontSize: { sm: ".8rem", md: "1rem" },
                    color: "footerText.main",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Terms & Conditions
                </Typography>
                <Typography
                  onClick={() => navigate("/refundAndCancellationPolicy")}
                  fontWeight={400}
                  sx={{
                    fontSize: { sm: ".8rem", md: "1rem" },
                    color: "footerText.main",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Refund And Cancellation Policy
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  width: { xs: "50%", sm: "auto" },
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  sx={{
                    fontSize: { xs: "1.5rem", sm: "1rem", md: "1.125rem" },
                    color: "primaryText.main",
                    mb: { xs: -0.5, sm: 1, md: 1 },
                  }}
                >
                  Useful links
                </Typography>
                <Typography
                  onClick={() => navigate("/contactUs")}
                  fontWeight={400}
                  sx={{
                    fontSize: { sm: ".8rem", md: "1rem" },
                    color: "footerText.main",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Contact us
                </Typography>
                <Typography
                  onClick={() => navigate("/wantToListWithUs")}
                  fontWeight={400}
                  sx={{
                    fontSize: { sm: ".8rem", md: "1rem" },
                    color: "footerText.main",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  List your property
                </Typography>

                <NavLink
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  to={`https://www.linkedin.com/company/voyagerstales`}
                >
                  <Typography
                    fontWeight={400}
                    sx={{
                      fontSize: { sm: ".8rem", md: "1rem" },
                      color: "footerText.main",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    Careers
                  </Typography>
                </NavLink>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  sx={{
                    fontSize: { xs: "1.5rem", sm: "1rem", md: "1.125rem" },
                    color: "primaryText.main",
                    mb: { xs: -0.1, sm: 1, md: 1 },
                    mt: { xs: 3, sm: 0, md: 0 },
                  }}
                >
                  Contact us
                </Typography>

                <NavLink
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  to={`mailto:Info@voyagerstales.com`}
                >
                  <Typography
                    fontWeight={400}
                    sx={{
                      fontSize: { sm: ".8rem", md: "1rem" },
                      color: "primaryText.main",
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Suspense>
                    <MailIcon
                      sx={{ mr: 2, fontSize: { sm: "1.2rem", md: "1.4rem" } }}
                    /></Suspense>{" "}
                    Info@voyagerstales.com
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                color: "footerText.main",
                textAlign: "center",
                mb: { xs: 2, sm: 3, md: 3 },
              }}
            >
              All rights reserved  &copy;2023 Epic Voyage Pvt. Ltd.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
