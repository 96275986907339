import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const userRegisteration = createAsyncThunk( 
  "userRegisteration",
  async ({ url,data }) => {
    try {
      const response = await axios.post(url,data, {
        headers: {
          // authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response;
    }
  }
);

export const userConfirm = createAsyncThunk("userConfirm", async ({ url }) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return response ? response?.data : null;
  } catch (error) {
    return null;
  }
});

export const userLogin = createAsyncThunk(
  "userLogin",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response;
    }
  }
);

export const ssoRegistration = createAsyncThunk(
  "ssoRegistration",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

export const ssoLogin = createAsyncThunk(
  "ssoLogin",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        // headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

export const contactUS = createAsyncThunk(
  "contactUS",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);


const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    userData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload?.user;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(ssoRegistration.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem("userId", action.payload?.user?.id);
        localStorage.setItem("token", action.payload?.user?.Token);
        state.userData = action.payload?.user;
      })
      .addCase(ssoLogin.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem("userId", action.payload?.user?.id);
        localStorage.setItem("token", action.payload?.user?.Token);
        state.userData = action.payload?.user;
      })
  },
});

export default userSlice.reducer;
