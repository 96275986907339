import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getCurrencyConversion = createAsyncThunk(
    "getCurrencyConversion",
    async ({url}) => {
      try {
        const response = await fetch(url)
        const data = await response.json();
        return data
      } catch (error) {
        return null;
      }
    }
  );

  const currencyConversionSlice = createSlice({
    name: "currencyConversion",
  initialState: {
    data: null,
  },
  reducers: {
    updateCurrencyData: (state, action) => {
        state.data = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getCurrencyConversion.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCurrencyConversion.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    })
    .addCase(getCurrencyConversion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
},
  })

  export const { updateCurrencyData } = currencyConversionSlice.actions;

  export default currencyConversionSlice.reducer;