import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBrowseProperty = createAsyncThunk(
  "getBrowseProperty",
  async ({ url }) => {
    try {
      const response = await axios.get(url)
      // const response = await axios.get(url, {
      //   headers: {
      //     authorization: 'Token ' + "edea5e74cc7ffba1f71d885d26c4155603aef88e",
      //   },
      // });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

const browseByPropertySlice = createSlice({
  name: "BrowseByPropertyDetails",
  initialState: {
    loading: false,
    singleBrowseProperty: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrowseProperty?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBrowseProperty?.fulfilled, (state, action) => {
        state.loading = false;
        state.singleBrowseProperty = action.payload?.data;
      })
      .addCase(getBrowseProperty?.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default browseByPropertySlice.reducer;
