import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#232674", // blue
    },
    secondary: {
      main: "#E87B25", // orange
    },
    primaryText: {
      main: "#1E1E1E", // font black color
    },
    secondaryText: {
      main: "#888", // gray text
    },
    ternaryText: {
      main: "#949494", // light gray text
    },

    black: {
      main: "#000000", // Black
    },
    white: {
      main: "#FFFFFF", // White
    },
    footerText: {
      main: "#666", // gray
    },
    border: {
      main: "#E7E7E7", // gray
    },
  },
  typography: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
  },
});
