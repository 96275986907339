import React, { lazy } from 'react';
import { compose } from 'redux';

const HomePage = lazy(() => import('./Pages/HomePage'));
const Registration = lazy(() => import('./Pages/User/Registration'));
const Login = lazy(() => import('./Pages/User/Login'));
const ProductDetailPage = lazy(() => import('./Pages/ProductDetailPage'));
const AboutUsPage = lazy(() => import('./Pages/AboutUsPage'));
const PrivacyPolicyPage = lazy(() => import('./Pages/PrivacyPolicyPage'));
const TermsAndConditionsPage = lazy(() => import('./Pages/TermsAndConditionsPage'));
const HowWeWorkPage = lazy(() => import('./Pages/HowWeWorkPage'));
const WantToListWithUsPage = lazy(() => import('./Pages/WantToListWithUsPage'));
const PropertyListingPage = lazy(() => import('./Pages/PropertyListingPage'));
const BrowserByPropertyListingPage = lazy(() => import('./Pages/BrowserByPropertyListingPage'));
const ContactUsPage = lazy(() => import('./Pages/ContactUsPage'));
const Thankyou = lazy(() => import('./Pages/Thankyou'));
const ProfileDashboardPage = lazy(() => import('./Pages/ProfileDashboardPage'));
const RefundAndCancellationPolicy = lazy(() => import('./Pages/RefundAndCancellationPolicy'));
const ListYourProperty = lazy(() => import('./Pages/ListYourProperty'));
const PreviewYourProperty = lazy(() => import('./Pages/PreviewYourProperty'));
const Help = lazy(() => import('./Pages/Help'));
const PaymentGateway = lazy(() => import('./Pages/PaymentGateway'));
const PaymentSummary = lazy(() => import('./Pages/PaymentSummary'));
// const Packages = lazy(() => import('./Pages/Packages'));
const SubscriptionThankyouPage = lazy(() => import('./Pages/SubscriptionThankyouPage'));
// const PackagesDetails = lazy(() => import('./Pages/PackagesDetails'));
// const FlightPage = lazy(() => import('./Pages/Flight'));
// const FlightBookingPage = lazy(() => import('./Pages/FlightBooking'));
// const FlightCheckout = lazy(() => import('./Pages/FlightCheckout'));
// const BusHomepage = lazy(() => import('./Pages/Bus/Homepage'));
// const BusBooking = lazy(() => import('./Pages/Bus/Booking'));
// const BusCheckout = lazy(() => import('./Pages/Bus/Checkout'));
// const TrainHomePage = lazy(() => import('./Pages/Train/Homepage'));
// const TrainDetail = lazy(() => import('./Pages/Train/TrainDetail'));
// const TrainBooking = lazy(() => import('./Pages/Train/TrainBooking'));
// const ESimHomepage = lazy(() => import('./Pages/ESIM/Homepage/Index.js'));

export const routes = [
  {
    path: "/",
    component: <HomePage />,
  },
  {
    path: "/registration",
    component: <Registration />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/pdp/:property_name/:id",
    component: <ProductDetailPage />,
  },
  {
    path: "/aboutUs",
    component: <AboutUsPage />,
  },
  {
    path: "/PrivacyPolicy",
    component: <PrivacyPolicyPage />,
  },
  {
    path: "/TermsAndConditions",
    component: <TermsAndConditionsPage />,
  },
  {
    path: "/howWeWork",
    component: <HowWeWorkPage />,
  },
  {
    path: "/wantToListWithUs",
    component: <WantToListWithUsPage />,
  },
  {
    path: "/listYourProperty",
    component: <ListYourProperty />,
  },
  {
    path: "/propertyListing/:country",
    component: <PropertyListingPage />,
  },
  {
    path: "/browserByPropertyListing/:property",
    component: <BrowserByPropertyListingPage />,
  },
  {
    path: "/contactUs",
    component: <ContactUsPage />,
  },
  {
    path: "/thankyou",
    component: <Thankyou />,
  },
  {
    path: "/profileDashboard",
    component: <ProfileDashboardPage />,
  },
  {
    path: "/refundAndCancellationPolicy",
    component: <RefundAndCancellationPolicy />,
  },
  {
    path: "/previewYourProperty",
    component: <PreviewYourProperty />,
  },
  {
    path:"/help",
    component: <Help />,
  },
  {
    path:"/paymentSummary",
    component: <PaymentSummary />,
  },
  {
    path:"/paymentGateway",
    component: <PaymentGateway />,
  },
  {
    path:"/subscriptionthankyou",
    component: <SubscriptionThankyouPage />,
  },
  // {
  //   path:"/packages",
  //   component: <Packages />,
  // },
  // {
  //   path:"/packagesDetails",
  //   component: <PackagesDetails />,
  // },
  // {
  //   path:"/flights",
  //   component: <FlightPage />,
  // },
  // {
  //   path:"/flightsBooking",
  //   component: <FlightBookingPage />,
  // },
  // {
  //   path:"/flightCheckout",
  //   component: <FlightCheckout />,
  // },
  // {
  //   path:"/bus",
  //   component: <BusHomepage />,
  // },
  // {
  //   path:"/busBooking",
  //   component: <BusBooking />,
  // },
  // {
  //   path:"/busCheckout",
  //   component: <BusCheckout />,
  // },
  // {
  //   path:"/train",
  //   component: <TrainHomePage />
  // },
  // {
  //   path:"/trainDetail",
  //   component: <TrainDetail />
  // },
  // {
  //   path:"/trainBooking",
  //   component:<TrainBooking />
  // },
  // {
  //   path:'/E-sim',
  //   component:<ESimHomepage />
  // },
];
