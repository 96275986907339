import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllPropertyType = createAsyncThunk(
  "getAllPropertyType",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);
export const getAllAmenities = createAsyncThunk(
  "getAllAmenities",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);
export const getAllHouseRules = createAsyncThunk(
  "getAllHouseRules",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);
export const getAllCountries = createAsyncThunk(
  "getAllCountries",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);
export const getTopAmenities = createAsyncThunk(
  "getTopAmenities",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

export const postForm = createAsyncThunk(
  "postForm",
  async ({ url, data, contentType }) => {
    try {
      // Initialize headers with the authorization token
      let headers = {
        authorization: 'Token ' + localStorage.getItem('token'),
      };

      // Set the 'Content-Type' header only if 'contentType' is provided
      if (contentType) {
        headers['Content-Type'] = contentType;
      }

      // Send the POST request with the appropriate headers
      const response = await axios.post(url, data, { headers });
      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

const listPropertySlice = createSlice({
  name: "list property",
  initialState: {
    loading: false,
    allPropertyType: null,
    allAmenities:null,
    allHouseRules:null,
    allCountries:null,
    topAmenities:null,
    previewData:null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPropertyType?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPropertyType?.fulfilled, (state, action) => {
        state.loading = false;
        state.allPropertyType = action.payload.data;
      })
      .addCase(getAllPropertyType?.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllAmenities?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAmenities?.fulfilled, (state, action) => {
        state.loading = false;
        state.allAmenities = action.payload.data;
      })
      .addCase(getAllAmenities?.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllHouseRules?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllHouseRules?.fulfilled, (state, action) => {
        state.loading = false;
        state.allHouseRules = action.payload.data;
      })
      .addCase(getAllHouseRules?.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllCountries?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCountries?.fulfilled, (state, action) => {
        state.loading = false;
        state.allCountries = action.payload;
      })
      .addCase(getAllCountries?.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTopAmenities?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTopAmenities?.fulfilled, (state, action) => {
        state.loading = false;
        state.topAmenities = action.payload;
      })
      .addCase(getTopAmenities?.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postForm?.pending, (state) => {
        state.loading = true;
      })
      .addCase(postForm?.fulfilled, (state, action) => {
        state.loading = false;
        state.previewData = action?.payload?.data;
      })
      .addCase(postForm?.rejected, (state) => {
        state.loading = false;
      })
  },
});

export default listPropertySlice.reducer;
