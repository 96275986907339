import { Newspaper } from "@mui/icons-material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import produce from "immer"



export const getHomeDashboard = createAsyncThunk(
  "getHomeDashboard",
  async ({ url }) => {
    try {
      
      const response = await axios.get(url, {
        headers: {
          // authorization: 'Token ' + localStorage.getItem('token'),
        },
      });

      return response ? response?.data : null;
    } catch (error) {
      return null;
    }
  }
);

const homeSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    homeData: null,
  },
  reducers: {
    updateTrendingProperties: (state, action) => {
      return produce(state, draftState => {
        if (draftState.homeData) {
          draftState.homeData.trending_properties = action.payload;
        }
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeDashboard?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeDashboard?.fulfilled, (state, action) => {
        state.loading = false;
        state.homeData = action.payload;
      })
      .addCase(getHomeDashboard?.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {updateTrendingProperties} = homeSlice.actions;

export default homeSlice.reducer;
